<template>
    <div class="page-placement">
        <Block 
            v-for="(content,index) in contents" 
            :key="index" 
            :content="content.content" >
        </Block>
    </div>
</template>

<script>
import Block from './Block';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "PagePlacement",
    components: {
        Block
    },
    props: {
        placement_key: {
            type: String,
            required: true
        }
    },
    setup(props){
        const store = useStore();

        const contents = computed(() => store.getters['page/getContents'](props.placement_key));

        return {
            contents
        }
        
    }

}
</script>

<style scoped>

</style>